<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="bar_content">
        <div class="bar_value">
          <div class="title">OUR VISION</div>
          <div class="text">RUBYSTAR GLOBAL LTD is a financial technology company that seeks to provide accessible financial services to people in Nigeria.</div>
          <div class="hint">Our vision is to be one of the top 5 best online loan apps in the world, providing outstanding quality services, and making a significant impact in the lives of our customers.</div>
          <el-button type="primary">Download AjeLoan app</el-button>
        </div>
      </div>
      <div class="bar_img">
        <img v-if="devicePixelRatio == 2" src="@/assets/img/our_banner@2x.png">
        <img v-else src="@/assets/img/our_banner.png">
      </div>
    </div>

    <div class="block prompt">
      <div class="block_box">
        <div class="title">WHAT WE ARE</div>
        <div class="line"></div>
        <div class="text">We built a new type of credit-led digital bank: as people need loans to fund their business or for personal reasons, we offer them what they need with no collateral to make their lives easier. They cannot access those services from traditional banks so AjeLoan covers that unmet needs to help them thrive.</div>
      </div>
    </div>

    <div class="block list">
      <div class="block_box">
        <div class="title">OUR VALUES</div>
        <div class="list_content">
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Start with the Client</div>
            <div class="hint">We are passionate about improving access to financial services in the emerging world and go into exceptional depth to understand our client s pain-points</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Own, don t rent</div>
            <div class="hint">We are radical owners who claim 100% ownership for a task, and do not rest till we accomplish our goal. We think of owning a task like owning a property</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">We are all Scientists</div>
            <div class="hint">No matter our domain, we follow a systematic approach towards problem-solving through reduction, collaboration, and iteration</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">70/30 Principle</div>
            <div class="hint">We believe in speed over certainty, and avoid over-analysis by making decisions based on 70% of the available data and a 30% margin of error</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Fly High, Fly Low</div>
            <div class="hint">We fly high to strategize and make bold plans, then fly low and get our hands dirty to ensure the quality and comprehensiveness of the implementation</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Focus (2.5)</div>
            <div class="hint">In a fast-paced environment, it is always important to know what NOT to focus on. 2.5 projects per week is all that we can handle to balance depth and workload</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Growth Mindset</div>
            <div class="hint">Talent is just a starting point that can be exponentially enhanced through the application of curiosity and hard work, while iterating on problems</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Direct Feedback</div>
            <div class="hint">We value the power of regular & direct feedback, as this helps all of us grow. The How matters: Being courteous and good-natured costs nothing</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Churn 9 to 5</div>
            <div class="hint">We LOVE our work and measure output in terms of goals achieved. The 9 to 5 mentality is a vestige of corporate where employees don t like their jobs.</div>
          </div>
          <div class="list_content_item">
            <div class="img"></div>
            <div class="text">Talent has no Passport</div>
            <div class="hint">Talent is distributed equally across the world and we value & embrace adding different cultures, nationalities, and genders to our team</div>
          </div>
        </div>
      </div>
    </div>

    <div class="block join">
      <div class="block_box">
        <div class="join_content">
          <div class="title">We're doubling the size of our team this year.</div>
          <div class="text">We hire smart and ambitious team members to make our vision a reality for millions of people in Africa and Asia. Tech, product, design, operations, risk ... Join our offices or work remotely (for most roles)</div>
          <el-button type="primary" @click="$router.push('/ContactUs')">Join us</el-button>
        </div>
        <div class="join_img">
          <img v-if="devicePixelRatio === 2" src="@/assets/img/join@2x.png">
          <img v-else src="@/assets/img/join.png">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WhoWeAre',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/whoWeAre.scss';
    .list {
      .list_content {
        .list_content_item {
          &:nth-child(1) .img {
            @include sprite($our_icon_1);
          }
          &:nth-child(2) .img {
            @include sprite($our_icon_2);
          }
          &:nth-child(3) .img {
            @include sprite($our_icon_3);
          }
          &:nth-child(4) .img {
            @include sprite($our_icon_4);
          }
          &:nth-child(5) .img {
            @include sprite($our_icon_5);
          }
          &:nth-child(6) .img {
            @include sprite($our_icon_6);
          }
          &:nth-child(7) .img {
            @include sprite($our_icon_7);
          }
          &:nth-child(8) .img {
            @include sprite($our_icon_8);
          }
          &:nth-child(9) .img {
            @include sprite($our_icon_9);
          }
          &:nth-child(10) .img {
            @include sprite($our_icon_10);
          }
        }
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/whoWeAre@2x.scss';
    .list {
      .list_content {
        .list_content_item {
          &:nth-child(1) .img {
            @include sprite($our_icon_1-2x);
          }
          &:nth-child(2) .img {
            @include sprite($our_icon_2-2x);
          }
          &:nth-child(3) .img {
            @include sprite($our_icon_3-2x);
          }
          &:nth-child(4) .img {
            @include sprite($our_icon_4-2x);
          }
          &:nth-child(5) .img {
            @include sprite($our_icon_5-2x);
          }
          &:nth-child(6) .img {
            @include sprite($our_icon_6-2x);
          }
          &:nth-child(7) .img {
            @include sprite($our_icon_7-2x);
          }
          &:nth-child(8) .img {
            @include sprite($our_icon_8-2x);
          }
          &:nth-child(9) .img {
            @include sprite($our_icon_9-2x);
          }
          &:nth-child(10) .img {
            @include sprite($our_icon_10-2x);
          }
        }
      }
    }
  }
}

.page {
  @include pixel-ratio();
  .block {
    text-align: left;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #333333;
     padding: 10px 0px;
    }
    .line {
      height: 0.5px;
      background: #CCCCCC;
    }
    .el-button {
      margin-top: 30px;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 500px;
          }
        }
      }
    }
    &.prompt {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
    }
    &.list {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
      .list_content {
        margin-top: 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &_item {
          background: rgba(103, 45, 158, 0.03);
          border-radius: 4px;
          display: inline-block;
          margin-bottom: 16px;
          width: 30%;
          .img {
            width: 35px !important;
            height: 35px !important;
            border-radius: 35px;
            background: rgba(103, 45, 158, 0.1);
            margin-top: 35px;
            margin: 35px 20px 10px 20px;
          }
          .text {
            padding: 0px 20px;
          }
          .hint {
            margin-top: 15px;
            margin-bottom: 30px;
            color: #333333;
            padding: 0px 20px;
          }
        }
      }
    }
    &.join {
      margin-top: 33px;
      margin-bottom: 20px;
      .block_box {
        display: flex;
      }
      .join_content {
        padding: 10px;
        padding-top: 55px;
        margin-bottom: 20px;
        flex: 1;
      }
      .join_img {
        max-width: 396px;
        font-size: 0px;
        img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      &.bar {
        min-height: 600px;
        .bar_img {
          position: absolute;
          top: 150px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg,rgba(0,0,0,0.00)0%,#ffffff 100%);
          }
          img {
            height: 100%;
            width: auto;
            position: absolute;
            right: 0;
            transform: translateX(8%);
          }
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          position: absolute;
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
          .bar_value {
            position: initial;
            top: 10px;
            transform: none;
          }
        }
      }
      &.list {
        .block_box {
          padding: 10px 0px;
        }
        .list_content {
          .list_content_item {
            width: 100%;
            text-align: left;
            margin-bottom: 0px;
            &:nth-child(2n + 2) {
              background-color: white;
            }
          }
        }
      }
      &.join {
        .block_box {
          display: block;
        }
        .join_content {
          padding-top: 0px;
        }
        .join_img {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
